import logo from './logo.svg';
import './App.css';

function App() {
  window.location="http://www.newwiwis26.com/uncategorized/3-year-building-warm-survival-shelter-free-bushcreaft-hut-bushwalking-in-the-woods/?amp=1";
  return (
    <div className="App">
    
    </div>
  );
}

export default App;
